<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("unifiedRegisterOfUnscrupulousPerformers") }}
				</AppText>
			</div>

			<div class="section__top-right d-flex">
				<div class="section__top-search mr-15">
					<app-input
						class="auction-header__input filter__search"
						:placeholder="this.$t('searchByTaxNumber')"
						style="width: 220px"
						v-model="userInn"
					>
						<template #prepend>
							<img
								src="@/assets/icons/search.svg"
								@click.self="getAllUsers"
								style="width: 16px; cursor: pointer"
							/>
						</template>
					</app-input>
				</div>

				<AppButton @click="reset" theme="info" sides="20" height="40" class="mr-15">
					<img src="../../assets/icons/repeat.svg" />
				</AppButton>

				<AppButton theme="info" sides="20" height="40" style="background-color: #e3f8ed" @click="downloadExcel">
					<img src="../../assets/icons/excel.svg" />
					<a class="excel" download href="../../assets/files/реестр_недобросовестных.xlsx"></a>
				</AppButton>
			</div>
		</div>
		<!-- {{ usersList }} -->
		<div class="section__body">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th class="text-bold">№</th>
							<th class="text-bold">{{ $t("organizationName") }}</th>
							<th class="text-bold">{{ $t("reason") }}</th>
							<th class="text-bold">{{ $t("inn") }}</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center" v-for="(user, index) in usersList" :key="user.id">
							<td>{{ index + 1 }}</td>
							<td>{{ user.name }}</td>
							<td>{{ user.note }}</td>
							<td>{{ user.inn }}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- <AppPagination show-list /> -->
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButton from "../../components/shared-components/AppButton";
import AppPagination from "../../components/shared-components/AppPagination";
// import allUsers from "./../../assets/files/reyestr_ned.json";
export default {
	name: "index",
	data: () => ({
		usersList: [],
		userInn: "",
	}),
	components: { AppPagination, AppButton, AppInput, AppText },
	async created() {
		this.getAllUsers();
	},
	methods: {
		async getAllUsers() {
			let params = {
				tin: this.userInn,
			};
			const response = await this.$api.get("api-company/Company/BlackCompanies", { params });
			this.usersList = response.data.result;
		},
		downloadExcel() {
			document.querySelector("a.excel").click();
		},
		async reset() {
			this.userInn = "";
			await this.getAllUsers();
		},
	},
};
</script>

<style scoped lang="scss">
.app-input__field input {
	padding-left: 0 !important;
}
</style>

